<div class="container">
  <img class="bg-image" src="../../../../../assets/images/FriendsWithBenefits/abc.jpg" />
  <div class="inner-container" style="margin-top: 25px;">
    <img class="top-image" src="../../../../../assets/images/FriendsWithBenefits/aalogo.jpg" />
    <form [formGroup]="emailForm" autocomplete="off">
      <!-- store is selected first and applied to all form submissions -->
      <div class="form-container" *ngIf="isStoreEmpty">
        <div class="store-select-container">
          <select class="form-input" id="store" formControlName="store" required>
            <option value="" disabled selected>Select store</option>
            <option *ngFor="let storeName of storeList.stores" [value]="storeName">{{ storeName }}</option>
          </select>
        </div>
        <!-- This section also only loads when store has not been saved, the button is u to set the store and load the next section -->
        <div class="button-container">
          <button class="btn btn-submit" type="button" (click)="storeSelect()">Select</button>
        </div>
      </div>

      <!-- This section loads when the form is being processed -->
      <div class="overlay" *ngIf="isProcessing" (click)="closeOverlay()">
        <div class="overlay-text">
          {{ processingText }}
        </div>
        <div class="button-container">
          <button class="btn btn-std" type="button" (click)="closeOverlay()">Close</button>
        </div>
      </div>

      <!-- This section loads when store has been saved -->
      <div class="form-container" *ngIf="!isStoreEmpty && !isFormSubmitted">
        <div class="form-header">
          <p class="">{{ store }} sign up!</p>
          <p class="">Receive a <strong>free gift</strong> with your first purchase,</p>
          <p class=""><strong>20% OFF</strong> your first online purchase,</p>
          <p class="">access to our <strong>WEEKLY</strong> promotions,</p>
          <p class="">and exclusive birthday presents!</p>
        </div>
        <div class="field-container">
          <label class="form-label" for="customEmail">Email:</label>
          <input class="form-input" type="email" id="customEmail" formControlName="customEmail" required>
        </div>
        <div class="field-container">
          <label class="form-label" for="customFirstName">First Name:</label>
          <input class="form-input" type="text" id="customFirstName" formControlName="customFirstName" required>
        </div>
        <div class="field-container">
          <label class="form-label" for="customLastName">Last Name:</label>
          <input class="form-input" type="text" id="customLastName" formControlName="customLastName" required>
        </div>
        <div class="field-container">
          <label class="form-label" for="customCountry">Country:</label>
          <select class="form-input" id="customCountry" formControlName="customCountry" required>
            <option *ngFor="let country of countryList" [value]="country.en_short_name">{{country.en_short_name}}</option>
          </select>
        </div>
        <div class="field-container">
          <label class="form-label" for="">Date of Birth:</label>
          <!--<div formGroupName="customDateOfBirth" class="form-input-dateOfBirth-container">
      <input class="form-input-dateOfBirth-field" type="number" id="customDateOfBirthDay" formControlName="day" placeholder="Day">
      <input class="form-input-dateOfBirth-field" type="number" id="customDateOfBirthMonth" formControlName="month" placeholder="Month">
      <input class="form-input-dateOfBirth-field" type="number" id="customDateOfBirthYear" formControlName="year" placeholder="Year">
    </div>-->
          <div formGroupName="customDateOfBirth" class="form-input-dateOfBirth-container">
            <select class="form-input-dateOfBirth-field-day" formControlName="day" (change)="onDayChange($event)">
              <option value="" disabled selected>Day</option>
              <option *ngFor="let day of days" [value]="day">{{ day }}</option>
            </select>
            <select class="form-input-dateOfBirth-field-month" formControlName="month" (change)="onMonthChange($event)">
              <option value="" disabled selected>Month</option>
              <option *ngFor="let month of months" [value]="month.value">{{ month.name }}</option>
            </select>
            <select class="form-input-dateOfBirth-field-year" formControlName="year" (change)="onYearChange($event)">
              <option value="" disabled selected>Year</option>
              <option *ngFor="let year of years" [value]="year">{{ year }}</option>
            </select>
          </div>

        </div>

        <div *ngIf="emailForm.get('customDateOfBirth.day').invalid && emailForm.get('customDateOfBirth.day').touched">
          <p class="text-danger">Day is required and must be between 1 and 31.</p>
        </div>
        <div *ngIf="!emailForm.get('customDateOfBirth.day').invalid && emailForm.get('customDateOfBirth.month').invalid && emailForm.get('customDateOfBirth.month').touched">
          <p class="text-danger">Month is required.</p>
        </div>
        <div *ngIf="!emailForm.get('customDateOfBirth.day').invalid && !emailForm.get('customDateOfBirth.month').invalid && emailForm.get('customDateOfBirth.year').invalid && emailForm.get('customDateOfBirth.year').touched">
          <p class="text-danger">Year is required and must be between {{ currentYearMinus120 }} and {{ currentYearMinus10 }}.</p>
        </div>
        <div *ngIf="emailForm.get('customDateOfBirth').invalid && emailForm.get('customDateOfBirth.year').touched && emailForm.get('customDateOfBirth.month').touched && emailForm.get('customDateOfBirth.day').touched && !emailForm.get('customDateOfBirth.day').invalid && !emailForm.get('customDateOfBirth.month').invalid && !emailForm.get('customDateOfBirth.year').invalid">
          <p class="text-danger">Invalid date.</p>
        </div>

        <div class="button-container">
          <button class="btn btn-reset" type="button" (click)="resetForm()">Reset</button>
          <button class="btn btn-submit" type="submit" (click)="onSubmit()" [disabled]="emailForm.invalid">Subscribe</button>
        </div>
      </div>
      <!-- This section loads when the form has been submitted -->
      <div class="form-container" *ngIf="isFormSubmitted">
        <div class="form-header">
          <p class="">Thank you for signing up!</p>
          <br />
          <p class="">Click New Form to register a new person.</p>
        </div>
        <div class="button-container">
          <button class="btn btn-std" type="button" (click)="resetForm()">New Form</button>
        </div>
      </div>
    </form>
  </div>
</div>
<!--</div>-->
