import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { FormService } from '../../../services/forms/form.service';
import { StoresList } from '../../../models/forms/storeList';
import { Country } from '../../../models/forms/country';
import { Form } from '../../../models/forms/form';
import { CookieService } from 'ngx-cookie-service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';

@Component({
  selector: 'form-component',
  templateUrl: './form-view.component.html',
  styleUrls: ['./form-view.component.scss'],
})
export class FormComponent {

  store: string = '';

  days: number[] = Array.from({ length: 31 }, (_, i) => i + 1); // Array of days from 1 to 31 for the day input dropdown/type

  months: { name: string, value: number }[] = [
    { name: 'January', value: 1 },
    { name: 'February', value: 2 },
    { name: 'March', value: 3 },
    { name: 'April', value: 4 },
    { name: 'May', value: 5 },
    { name: 'June', value: 6 },
    { name: 'July', value: 7 },
    { name: 'August', value: 8 },
    { name: 'September', value: 9 },
    { name: 'October', value: 10 },
    { name: 'November', value: 11 },
    { name: 'December', value: 12 }
  ];
  years: number[] = Array.from({ length: 111 }, (_, i) => new Date().getFullYear() - 10 - i); // Array of years from the current year minus 10 to 120 years ago

  filteredDays: Observable<number>[] = [];
  filteredMonths: Observable<number>[] = [];
  filteredYears: Observable<number>[] = [];

  isStoreEmpty: boolean = true;
  isFormSubmitted: boolean = false;

  emailForm: FormGroup;
  storeList: StoresList;
  countryList: Country[] = [];
  isProcessing: boolean = false;
  processingText: string = 'Processing...';
  formSubmittedError: boolean = false;
  dateInvalid: boolean = true;

  currentYearMinus120: number = new Date().getFullYear() - 120;
  currentYearMinus10: number = new Date().getFullYear() - 10;

  constructor(private fb: FormBuilder, private formService: FormService, private cookieService: CookieService) { }

  ngOnInit() {
    this.emailForm = this.fb.group({
      customEmail: ['', [Validators.required, Validators.email]],
      customFirstName: ['', [Validators.required, Validators.pattern(/^[a-zA-Z]*$/)]],
      customLastName: ['', [Validators.required, Validators.pattern(/^[a-zA-Z]*$/)]],
      customDateOfBirth: this.fb.group({
        day: ['', [Validators.required, Validators.min(1), Validators.max(31)]],
        month: ['', [Validators.required, Validators.min(1), Validators.max(12)]],
        year: ['', [Validators.required, Validators.min(this.currentYearMinus120), Validators.max(this.currentYearMinus10)]]
      }, { validators: this.dateValidator() }), 
      store: ['', Validators.required],
      customCountry: ['United States', [Validators.required, Validators.pattern(/^[a-zA-Z\s]+$/)]]
    });



    this.formService.getStoresList().subscribe(data => {
      this.storeList = data;
    });

    this.formService.getCountriesList().subscribe(
      data => {
        this.countryList = data;
      },
      error => {
        console.error('Error fetching countries:', error);
      }
    );

    // Retrieve the store from cookies
    const savedStore = this.cookieService.get('selectedStore');
    if (savedStore) {
      this.store = savedStore;
      this.emailForm.patchValue({ store: savedStore });
      this.isStoreEmpty = false;
    }

    // Subscribe to value changes to remove non-letter characters
    this.emailForm.get('customFirstName')?.valueChanges.subscribe(value => {
      const sanitizedValue = value.replace(/[^a-zA-Z]/g, '');
      this.emailForm.get('customFirstName')?.setValue(sanitizedValue, { emitEvent: false });
    });

    this.emailForm.get('customLastName')?.valueChanges.subscribe(value => {
      const sanitizedValue = value.replace(/[^a-zA-Z]/g, '');
      this.emailForm.get('customLastName')?.setValue(sanitizedValue, { emitEvent: false });
    });


  }

  onDayChange(event: Event) {
    const input = event.target as HTMLInputElement;
    const value = parseInt(input.value, 10);
    if (value >= 1 && value <= 31) {
      this.emailForm.get('customDateOfBirth.day')?.setValue(value);
    } else {
      this.emailForm.get('customDateOfBirth.day')?.setValue('');
    }
  }

  onMonthChange(event: Event) {
    const input = event.target as HTMLInputElement;
    const value = parseInt(input.value, 10);
    if (value >= 1 && value <= 12) {
      this.emailForm.get('customDateOfBirth.month')?.setValue(value);
    } else {
      this.emailForm.get('customDateOfBirth.month')?.setValue('');
    }
  }

  onYearChange(event: Event) {
    const input = event.target as HTMLInputElement;
    const value = parseInt(input.value, 10);
    if (value >= this.currentYearMinus120 && value <= this.currentYearMinus10) {
      this.emailForm.get('customDateOfBirth.year')?.setValue(value);
    } else {
      this.emailForm.get('customDateOfBirth.year')?.setValue('');
    }
  }

  //check if date is valid, for example, 31st of February is not valid
  dateValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      const day = control.get('day')?.value;
      const month = control.get('month')?.value;
      const year = control.get('year')?.value;

      if (day && month && year) {
        const date = new Date(year, month - 1, day);
        if (date.getDate() === day && date.getMonth() === month - 1 && date.getFullYear() === year) {
          this.dateInvalid = false;
          return null;
        }
      }
      this.dateInvalid = true;
      return { dateInvalid: true };
    };
  }

  storeSelect() {
    const selectedStore = this.emailForm.get('store')?.value;
    if (selectedStore === '') {
      this.isStoreEmpty = true;
    } else {
      this.isStoreEmpty = false;
      this.store = selectedStore; // Update the store variable
      this.cookieService.set('selectedStore', selectedStore); // Save the store to cookies
    }
  }

  onSubmit() {
    if (this.emailForm.invalid) {
      return;
    }

    this.isProcessing = true;

    // Extract day, month, and year
    const day = this.emailForm.value.customDateOfBirth.day;
    const month = this.emailForm.value.customDateOfBirth.month - 1; // JavaScript months are 0-based
    const year = this.emailForm.value.customDateOfBirth.year;

    // Create a Date object
    const dateOfBirth = new Date(year, month, day);

    // Format the date as a string (ISO 8601 format)
    const formattedDateOfBirth = dateOfBirth.toISOString().split('T')[0]; // Format as YYYY-MM-DD

    const form: Form = {
      emailAddress: this.emailForm.value.customEmail,
      firstName: this.emailForm.value.customFirstName,
      lastName: this.emailForm.value.customLastName,
      dateOfBirth: formattedDateOfBirth, // Use the formatted date
      store: this.emailForm.value.store,
      country: this.emailForm.value.customCountry,
      ListID: '',
      APIKey: '',
      serverPrefix: '',
      status: ''
    };

    this.formService.subscribeUser(form).subscribe(
      response => {
        console.log(JSON.stringify(response));
        const responseString = JSON.stringify(response);
        // response of 0 means success
        if (responseString === '0') {
          this.isProcessing = false;
          this.isFormSubmitted = true;
          //reset the form automatically within 10 seconds
          setTimeout(() => {
            this.resetForm();
          }, 10000);
        } else {
          this.handleErrorResponse(responseString);
        }
      },
      error => {
        console.error('Error:', JSON.stringify(error));
        this.isProcessing = true;
        this.formSubmittedError = true;
        this.processingText = error; // Display the error message
      }
    );
  }

  handleErrorResponse(responseString: string) {
    this.isProcessing = true;
    this.formSubmittedError = true;
    switch (responseString) {
      case '1':
        this.processingText = 'This email address is already subscribed. Enter another email.';
        break;
      case '2':
        this.processingText = 'Invalid email address. Enter another email.';
        break;
      case '3':
        this.processingText = 'Unknown error, please retry.';
        break;
      case '4':
        this.processingText = 'Saved to Mailchimp but not on server.';
        break;
      default:
        this.processingText = 'Unknown error, please retry.';
        break;
    }
  }

  closeOverlay() {
    this.isProcessing = false;
    this.processingText = 'Processing...';
  }

  resetForm() {
    const currentStore = this.emailForm.get('store')?.value;
    this.emailForm.reset({
      customCountry: 'United States',
      store: currentStore
    });
    this.isProcessing = false;
    this.formSubmittedError = false;
    this.isFormSubmitted = false;
  }
}
